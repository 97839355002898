import * as React from "react";
import { graphql } from "gatsby";
import { Seo } from "../components/Seo";
import { Header, Footer, PressLayout } from "../components/layout/index";
import { BannerImage } from "../components/atoms/BannerImage";
import { t } from "../translations";

export default function Press({ data, pageContext }) {
  const { posts } = data;
  const { locale } = pageContext;
  return (
    <>
      <Seo locale={locale} title={t("press.title")} />
      <Header locale={locale} />
      <PressLayout posts={posts} locale={locale} />
      <BannerImage locale={locale} />
      <Footer locale={locale} />
    </>
  );
}

export const pageQuery = graphql`
  query PressListingByLocale($locale: String) {
    posts: allSanityPresse(
      filter: { locale: { eq: $locale } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      nodes {
        id
        title
        link
        category
        publishedAt(formatString: "DD MMMM YYYY", locale: "fr")
      }
    }
  }
`;
